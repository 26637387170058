@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Chivo+Mono&display=swap");

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.notificationWrapper {
  @apply flex flex-row items-center start-0 w-96 px-4 py-6 shadow-2xl hover:shadow-none transform-gpu translate-y-0 hover:translate-y-1 rounded-xl relative transition-all duration-500 ease-in-out;
}

.notificationWrapper.true {
  @apply bg-gradient-to-r from-lime-400 to-lime-500 text-neutral-950;
}

.notificationWrapper.false {
  @apply bg-gradient-to-r from-red-600 to-red-700 text-slate-100;
}

.iconWrapper {
  @apply text-2xl;
}

.contentWrapper {
  @apply flex flex-col items-start justify-center ml-4 cursor-default;
}

.contentWrapper h1 {
  @apply text-base font-semibold leading-none tracking-wider;
}

.contentWrapper p {
  @apply text-sm mt-2 leading-relaxed tracking-wider;
}

.closeIcon {
  @apply absolute top-2 right-2 cursor-pointer text-sm;
}

.backgroudRemoval .PhotoView-Slider__BannerWrap {
  background-color: transparent;
}